import TYPES from '@/types';

import Vue from 'vue';

// Application
import SuspendAllianzRecurringContributionsCommand from '@/modules/my-investment/allianz/recurring-contributions/application/commands/suspend-allianz-recurring-contributions-command';

// Domain
import {
  StateManager,
} from '@/modules/my-investment/allianz/recurring-contributions/domain/state/state-manager';
import {
  StateManager as AllianzAccountStateManager,
} from '@/modules/my-investment/allianz/allianz-account/domain/state/state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import { DatetimeValue } from '@/modules/shared/domain/value-objects/datetime-value';

export default class SuspendContributionViewModel {
  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.SUSPEND_ALLIANZ_RECURRING_CONTRIBUTIONS_COMMAND)
  private readonly suspend_recurring_contribution_command!:
    SuspendAllianzRecurringContributionsCommand;

  @Inject(TYPES.ALLIANZ_RECURRING_CONTRIBUTIONS_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.ALLIANZ_ACCOUNT_STATE_MANAGER)
  private readonly account_state_manager!: AllianzAccountStateManager;

  @Inject(TYPES.DATETIME_VALUE)
  private datetime_value!: DatetimeValue;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  private readonly view: Vue;

  i18n_namespace = 'components.allianz-dashboard.deposits.suspend_contribution';

  is_loading = false;

  recurring_contribution_cancelled_successfully = false;

  was_applied_for_current_month = false;

  state = this.state_manager.state;

  account_state = this.account_state_manager.state;

  constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  cancel = () => {
    this.recurring_contribution_cancelled_successfully = false;
    this.was_applied_for_current_month = false;
    this.view.$emit('endProcess');
  }

  updateStateManagerAllianzAccount = async () => {
    this.account_state.account.home_desire = false;
    this.account_state.account.periodicity = '';
    this.account_state.account.amount = 0;
    this.state.item.home_desired = false;
    this.state.item.total_contribution = 0;
    this.state.item.contributions = [];
  }

  confirm = async () => {
    this.is_loading = true;
    try {
      const response = await this.suspend_recurring_contribution_command
        .execute({ cancellation_date: this.datetime_value.create() });
      this.was_applied_for_current_month = response.was_applied_for_current_month;
      this.recurring_contribution_cancelled_successfully = true;
      await this.updateStateManagerAllianzAccount();
    } catch {
      this.message_notifier.showErrorNotification(
        this.translate('error_on_cancel_recurring_contribution'),
      );
    } finally {
      this.is_loading = false;
    }
  }
}
